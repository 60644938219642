











































































import { Component, Vue } from 'vue-property-decorator';

import FlagshipProfilingStepGoalsViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-step-goals-view-model';

@Component({ name: 'FlagshipProfilingStepGoals' })
export default class FlagshipProfilingStepGoals extends Vue {
  flagship_goals_view_model = Vue.observable(
    new FlagshipProfilingStepGoalsViewModel(this),
  );

  created() {
    this.flagship_goals_view_model.initialize();
  }
}
